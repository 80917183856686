/********************/
/* Custom styles  */
/********************/

/*Logo on homepage*/
.reactLogo {
    width: 30%;
    min-width: 200px;
}
/*Active router link, set by React Router*/
.router-link-active {
    border-bottom: 3px solid #199EB8;
}

/*Override hover color on main menu from Bootswatch style*/
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    /*color: #199EB8;*/
    color: #fff;
}

/*Styles on contact form*/

.contact-form .input:invalid {
    border-color: red;
}
